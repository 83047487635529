import React from 'react'
import { Layout, Logo, SignupForm } from '../components'

const Signup = () => {
  return (
    <Layout>
      <div className='formWrapInner'>
        <Logo />
        <SignupForm />
      </div>
    </Layout>
  )
}

export default Signup
