const Content = () => {
  return (
    <>
      <div className='content'>
        <h1>
          <b>Welcome</b>to the Payper <span>API</span> Docs.
        </h1>
        <h2>Start accepting seriously awesome payments in Canada.</h2>
        <p>
          Here you’ll find everything you need to integrate to Payper. Payper is
          committed to offering an excellent experience for you and your
          customer. Have a suggestion? Let us know.
        </p>
      </div>

      <div className='hills'>
        <img src='/images/hills.png' alt='' />
      </div>
      <div className='guilloche left'>
        <img src='/images/guilloche-1.png' alt='' />
      </div>
      <div className='dime dime-1'>
        <img src='/images/dime-1.png' alt='' />
      </div>
      <div className='dime dime-2'>
        <img src='/images/dime-2.png' alt='' />
      </div>
      <div className='dime dime-3'>
        <img src='/images/dime-3.png' alt='' />
      </div>
      <div className='leaf'>
        <img src='/images/leaf.png' alt='' />
      </div>
    </>
  )
}

export default Content
