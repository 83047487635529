const InputField = ({ name, icon, formik, ...restProps }) => {
  const { handleChange, handleBlur, touched, errors } = formik

  return (
    <div className='inputWrap'>
      <div className='formGroup'>
        <label htmlFor={name}>{icon}</label>
        <input
          name={name}
          id={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={formik.values[name]}
          {...restProps}
        />
        <span className='form-border'></span>
      </div>
      <span className='inputError'>
        {touched[name] && errors[name] ? errors[name] : null}
      </span>
    </div>
  )
}

export default InputField
