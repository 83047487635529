import React from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const navigate = useNavigate()

  // Check every second if the token is still in the local storage
  const interval = setInterval(() => {
    const token = localStorage.getItem('token')

    // Remove the token from the local storage
    localStorage.removeItem('token')

    if (!token) {
      navigate('/signin')
      clearInterval(interval)
    }
  }, 100)

  return <></>
}

export default Logout
