import React from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

import { InputField } from '.'

const Signup = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
    }),
    onSubmit: async ({ name, email }, { resetForm, setStatus }) => {
      try {
        await axios.post('/api/v1/signup', { name, email })
        resetForm()
        setStatus({ success: true })
      } catch (error) {
        setStatus({ success: false })
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputField
        type='text'
        name='name'
        icon={
          <img
            width='22'
            src='/images/user.svg'
            alt='Password'
            style={{ filter: 'contrast(0.3)' }}
          />
        }
        placeholder='Full Name'
        formik={formik}
      />
      <InputField
        type='email'
        name='email'
        icon={<img width='23' src='/images/mail.png' alt='Email' />}
        placeholder='Email Address'
        formik={formik}
      />
      <br />
      {formik.status && formik.status.success === true && (
        <div className='formStatus success'>
          Thank you for signing up! We will contact you shortly.
        </div>
      )}
      {formik.status && formik.status.success === false && (
        <div className='formStatus error'>
          Something went wrong. Please try again.
        </div>
      )}
      <button type='submit' disabled={formik.isSubmitting || !formik.isValid}>
        Sign up
        {formik.isSubmitting && (
          <span style={{ marginLeft: 8 }}>
            <ClipLoader color='#fff' size={20} />
          </span>
        )}
      </button>
      <h5 className='accountMsg'>
        Already have an account? <Link to='/signin'>Sign in</Link>
      </h5>
    </form>
  )
}

export default Signup
