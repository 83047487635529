import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Nav } from '.'
import Content from './Content'

const Layout = ({ children }) => {
  const navigate = useNavigate()

  // Get the token from the local storage
  React.useEffect(() => {
    const token = localStorage.getItem('token')
    const expiresAt = localStorage.getItem('expiresAt')
    if (token && token !== '') {
      // Check if the token is expired
      if (new Date().getTime() > expiresAt) {
        console.log('Token expired')

        localStorage.removeItem('token')
        localStorage.removeItem('expiresAt')

        navigate('/signin')
      }

      console.log('Valid Token')

      // Redirect to the dashboard
      window.location.href =
        process.env.REACT_APP_REDIRECT_URL + `?auth_token=${token}`
    }
  }, [navigate])

  return (
    <main className='main'>
      <section className='contentWrap'>
        <Content />
      </section>
      <section className='formWrap'>
        <Nav />
        <div className='formWrapInner'>{children}</div>
      </section>
    </main>
  )
}

export default Layout
