import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <div className='logo'>
      <Link to='/'>
        <img src='/images/payper-logo.svg' alt='payper' />
        <h4 className='tagline'>Get in the fold</h4>
      </Link>
    </div>
  )
}

export default Logo
