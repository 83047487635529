import { Routes, Route } from 'react-router-dom'
import { Home, Logout, NotFound, Signin, Signup } from './pages'

import './App.css'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='signin' element={<Signin />} />
      <Route path='signup' element={<Signup />} />
      <Route path='logout' element={<Logout />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default App
