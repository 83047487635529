import axios from 'axios'

const signinUser = async (username, password) => {
  try {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    }

    const { data } = await axios.post(
      '/api/v1/signin',
      { username, password },
      config
    )

    if (data) {
      localStorage.setItem('token', data.token)

      // Set expiration to 1 day
      const oneDay = 24 * 60 * 60 * 1000
      const expiresAt = new Date().getTime() + oneDay
      localStorage.setItem('expiresAt', expiresAt)
    }

    return {
      success: true,
      data,
    }
  } catch (error) {
    return {
      success: false,
      error: error.message,
    }
  }
}

export default signinUser
